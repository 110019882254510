import styles from './adcomponent.module.css'

import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import proAdFull from '../../assets/images/pro-ad-full-one-blue.jpg'
import proAdLargeOne from '../../assets/images/pro-ad-large-one-blue.jpg'
import proAdLargeTwo from '../../assets/images/pro-ad-large-two-blue.jpg'
import proAdSmallOne from '../../assets/images/pro-ad-small-one-blue.jpg'
import proAdSmallTwo from '../../assets/images/pro-ad-small-two-blue.jpg'
import proAdSmallThree from '../../assets/images/pro-ad-small-three-blue.jpg'
import proAdSmallFour from '../../assets/images/pro-ad-small-four-blue.jpg'
import proAdSmallFive from '../../assets/images/pro-ad-small-five-blue.jpg'
import proAdSmallSix from '../../assets/images/pro-ad-small-six-blue.jpg'
import proAdVerticalOne from '../../assets/images/pro-ad-vertical-one-blue.jpg'
import proAdVerticalTwo from '../../assets/images/pro-ad-vertical-two-blue.jpg'
import proAdBottom from '../../assets/images/pro-ad-bottom.jpg'

import Icon from '../Icon'
import AdSense from '../AdSense'
import { useMediaQuery } from 'react-responsive'

export default function AdComponent({ 
    className, 
    type="", // "full" || "large" || "small" || "vertical" || "bottom" || "right"
    absoluteRemoveAds,
    noRemoveAds,
    skipProAd,

    adStyle,
    client="ca-pub-2440073876670864",
    slot="2521732577",
    responsive=null,

    onAdCompleted=()=>{},
    onAdLoaded=()=>{},
    onAdFailed=()=>{},
}) {
    
    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isHeightLarge = useMediaQuery({ maxHeight: 845 })
    const isHeightMedium = useMediaQuery({ maxHeight: 795 })
    const isHeightSmall = useMediaQuery({ maxHeight: 745 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isPro = useSelector(state => state.me.is_pro)
    const allow_web_ads = useSelector(state => state.auth.healthCheck.allow_web_ads)
    const only_show_web_pro_ads = useSelector(state => state.auth.healthCheck.only_show_web_pro_ads)

    //Prepare for render
    const margin = 
        type === "full" ? 
            "margin-left-64 margin-right-64" 
        : type === "large" ? 
            "margin-left-32 margin-right-32" 
        : type === "small" ?
            "margin-left-16 margin-right-16"
        : type === "vertical" ?
            "margin-left-64 margin-right-64"
        : type === "bottom" ?
            ""
        : type === "right" ?
            ""
        :
            ""
    
    const height =
        type === "full" ? 
            'auto'
        : type === "large" ? 
            'auto'
        : type === "small" ?
            'auto'
        : type === "vertical" ?
            isHeightSmall ? 460 : isHeightMedium ? 510 : isHeightLarge ? 550 : 600
        : type === "bottom" ?
            72
        : type === "right" ?
            180
        :
            200
    
    const maxHeight = 
        type === "full" ? 
            300
        : type === "large" ? 
            300
        : type === "small" ?
            350
        : type === "vertical" ?
            isHeightSmall ? 460 : isHeightMedium ? 510 : isHeightLarge ? 550 : 600
        : type === "bottom" ?
            100
        : type === "right" ?
            220
        :
            200

    //State
    const [adLoaded, setAdLoaded] = useState(false)
    const [_adFailed, setAdFailed] = useState(false)
    const random = useMemo(() => Math.random(), [])
    
    const adFailed = _adFailed || window.failedAdsOnSiteX1941

    //Set ad complete
    useEffect(() => {
        if (adFailed || adLoaded) {
            onAdCompleted()
        }
    }, [adFailed, adLoaded])

    //Render
    if (!allow_web_ads) {
        return null
    }

    if (isLoggedIn && isPro) {
        return null
    }

    if (
        !skipProAd &&
        (adFailed || only_show_web_pro_ads) &&
        (type === "full" || type === "large" || type === "small" || type === "vertical" || type === "bottom")
    ) {
        return (
            <div className={`${className} ${margin}`}>
                <Link to="/pro">
                    <div className={styles.proAdLink}>
                        {type === "full" &&
                            <img src={proAdFull} className={styles.proAdFull} alt="Musicboard Pro ad" /> 
                        }

                        {type === "large" &&
                            <img 
                            src={
                                random >= 0.5 ?
                                    proAdLargeOne
                                :
                                    proAdLargeTwo
                            } 
                            className={styles.proAdLarge} 
                            alt="Musicboard Pro ad"
                            /> 
                        }

                        {type === "small" &&
                            <img 
                            src={
                                random < 0.16667 ?
                                    proAdSmallOne
                                : random < 0.33333 ?
                                    proAdSmallTwo
                                : random < 0.5 ?
                                    proAdSmallThree
                                : random < 0.66667 ?
                                    proAdSmallFour
                                : random < 0.83333 ?
                                    proAdSmallFive
                                :
                                    proAdSmallSix    
                            } 
                            className={styles.proAdSmall} 
                            alt="Musicboard Pro ad"
                            /> 
                        }

                        {type === "vertical" &&
                            <img 
                            src={
                                random >= 0.5 ?
                                    proAdVerticalOne
                                :
                                    proAdVerticalTwo
                            } 
                            className={styles.proAdVertical} 
                            alt="Musicboard Pro ad"
                            /> 
                        }

                        {type === "bottom" &&
                            <img src={proAdBottom} className={styles.proAdBottom} alt="Musicboard Pro ad" /> 
                        }
                        
                        <span className={styles.proAdTitle}>Musicboard Pro</span>
                    </div>
                </Link>

                {!noRemoveAds &&
                    <div className={absoluteRemoveAds ? styles.absoluteRemoveAds : "flex-center margin-top-12"}>
                        <Link to="/pro" className={`flex-center ${styles.removeAds}`}>
                            <Icon icon='close' size={16} style={{ marginRight: 2, transform: 'scale(0.95) translateY(0.2px)' }} />
                            <h6 style={{ fontSize: 12 }}>REMOVE ADS</h6>
                        </Link>
                    </div>
                }
            </div>
        )
    }

    return (
        <div 
        className={`${className} ${isMobile ? "" : margin}`} 
        style={{
            ...adFailed ? { overflow: 'hidden', height: 0, opacity: 0, width: 0, marginTop: 0 } : {},
            ...adLoaded ? {} : { height: height, marginTop: 0 },
        }}
        >
            <div style={{ height: 'auto', height: height, maxHeight: maxHeight }}>
                <AdSense
                style={adStyle ? adStyle : { display: 'block', margin: 'auto' }}
                client={client}
                slot={slot}
                format={
                    type === "full" ?
                        "horizontal"
                    : type === "large" ?
                        "horizontal"
                    : type === "small" ?
                        "rectangle"
                    : type === "vertical" ?
                        "vertical"
                    : type === "bottom" ?
                        "horizontal"
                    : type === "right" ?
                        "horizontal"
                    :
                        "auto"
                }
                responsive={responsive ? responsive : "false"}
                onAdLoaded={() => {
                    onAdLoaded()
                    setAdLoaded(true)
                }}
                onAdFailed={() => {
                    onAdFailed()
                    setAdFailed(true)
                }}
                //adTest="on"
                //layout
                //layoutKey
                //pageLevelAds
                />
            </div>

            {!noRemoveAds && adLoaded &&
                <div className={absoluteRemoveAds ? styles.absoluteRemoveAds : "flex-center margin-top-12"}>
                    <Link to="/pro" className={`flex-center ${styles.removeAds}`}>
                        <Icon icon='close' size={16} style={{ marginRight: 2, transform: 'scale(0.95) translateY(0.2px)' }} />
                        <h6 style={{ fontSize: 12 }}>REMOVE ADS</h6>
                    </Link>
                </div>
            }
        </div>
    )
}
